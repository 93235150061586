import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, TextField, Button, Box, Autocomplete, Chip, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import SelectField from 'ui-component/SelectField';
import { useParams } from 'react-router';
import { FetchTemplateMenu, FetchMasterTemplateDetails } from '../../api';
import Loading from 'views/Loading';
import { toast } from 'react-toastify';
import { FetchAllMessageTemplates, SendTestMessageApi, sendTestMessageApi } from 'api/Master';

const initialValues = {
    id: '',
    template_value: '',
    template_value: '',
    variables: [],
    button_url: '',
    header_variable: ''
};
const inputStyles = (theme) => ({
    '& input': {
        background: theme.palette.background.paper
    },
    '& input:valid + fieldset': {
        borderColor: theme.palette.secondary[200],
        borderWidth: 0.5
    },
    '& input:valid:hover + fieldset': {
        borderColor: '#4050b5',
        borderWidth: 1
    },
    '& input:valid:focus + fieldset': {
        borderColor: '#4050b5',
        padding: '4px !important',
        borderWidth: 1,
        borderLeftWidth: 6
    }
});

const MessageTemplatePreview = () => {
    const  dealId  = useParams(); 

    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [showFields, setShowFields] = useState(false);
    const theme = useTheme();
    const [sort, setSort] = useState(-1);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [search, setSearch] = useState('');
    const [showTestMessageFields , setShowTestMessageFields] = useState(false)
    const [inputValue, setInputValue] = useState('');
    const [chipArray, setChipArray] = useState([]);
    const [variableError, setVariableError] = useState('');
    const [buttonUrlError, setButtonUrlError] = useState('');
    const [headerValueError, setHeaderValueError] = useState('');
    const [numberError, setNumberError] = useState('');
  

    // Fetch template details based on selected template
    const {
        isLoading: templateDetailStatusLoading,
        isRefetching: templateDetailRefetching,
        data: templateDetailData,
        refetch: refetchTemplateDetail,
        status: templateStatus
    } = FetchAllMessageTemplates({ page, sort, search, limit });

    const { mutateAsync: sendTestMessage, status: sendTestMessageStatus, error: sendTestMessageError } = SendTestMessageApi();
    
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: (values) => {
            toast.success('Form submitted successfully!');
        }
    });

    const handleFetchDetails = () => {
        if (selectedTemplateId) {
            refetchTemplateDetail();
            setShowFields(true);
        } else {
            toast.error('Please select a template before fetching details.');
        }
    };
    useEffect(() => {
        if (sendTestMessageStatus == 'success') {
            toast.success(' Message Sent!', {
                position: 'top-left',
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
        }
        if (sendTestMessageStatus == 'error') {
            toast.error(sendTestMessageError?.response?.data?.message, {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
        }
    }, [sendTestMessageStatus]);


    useEffect(() => {
        if (templateDetailData && templateDetailData.data && templateDetailData.data.length > 0) {
            const selectedTemplate = templateDetailData.data.find((template) => template.id === selectedTemplateId);
            if (selectedTemplate) {
                formik.setValues({
                    id: selectedTemplate.id,
                    value: selectedTemplate.value,
                    template_value: selectedTemplate.templateValue || '',
                    template: selectedTemplate.template,
                    variables: selectedTemplate.variable,
                    button_url: selectedTemplate.button_url || '',
                    header_variable: selectedTemplate.header_variable || '',
                    isHeaderVariableRequired: selectedTemplate.isHeaderRequired || false,
                    isButtonUrlRequired: selectedTemplate.isButtonUrlRequired || false,
                });
            } else {
                formik.resetForm();
            }
        }
    }, [templateDetailData, selectedTemplateId]);

    // Handle adding chip on Enter key press
    const handleChipAddition = (event, value) => {
        if (event.key === 'Enter' && value && !chipArray.includes(value)) {
            if (!isNaN(value)) {
                setChipArray((prev) => [...prev, value]);
                formik.setFieldValue('test_email', ''); 
            } else {
                toast.error('Please enter a valid number.');
            }
        }
    };

      // Handle input changes
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };
      // Handle key down to add chip when Enter is pressed
  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      // Prevent default behavior of submitting form or other actions
      event.preventDefault();

      // Validate input (must be a number)
      if (inputValue && !isNaN(inputValue) && !chipArray.includes(inputValue)) {
        setChipArray((prev) => [...prev, inputValue]);
        setInputValue(''); // Clear the input after adding
      } else {
        setNumberError('Please enter a valid number');
      }
    }
  };

  // Handle deleting chips
  const handleDelete = (chipToDelete) => {
    setChipArray((chips) => chips.filter((chip) => chip !== chipToDelete));
  };
   
  function validateName() {
    let isValid = true;

    if (formik.values.variables.some((variable) => !variable || variable === ''))  {
        setVariableError('All Variable is required');
        setTimeout(() => setVariableError(null), 4000);
        isValid = false;
    }

    if (showTestMessageFields  && chipArray.length === 0) {
        setNumberError('At least one number is required');
        setTimeout(() => setNumberError(null), 4000);
        isValid = false;
    }

    if (formik.values.isHeaderVariableRequired == true && (!formik.values.header_variable || formik.values.header_variable === '')) {
        setHeaderValueError('Header Variable is required');
        setTimeout(() => setHeaderValueError(null), 4000);
        isValid = false;
    }

    if (formik.values.isButtonUrlRequired == true && (!formik.values.button_url || formik.values.button_url === '')) {
        setButtonUrlError('Button URL is required');
        setTimeout(() => setButtonUrlError(null), 4000);
        isValid = false;
    }

    return isValid;
}


    if (templateDetailStatusLoading) return <Loading />;

    return (
        <Box sx={{ marginTop: '25px' }}>
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                    <div className="row mt-2">
                        <div className="col-sm-6 col-12">
                            <SelectField
                                name="template_id"
                                label="Select Template"
                                options={templateDetailData?.data.map((template) => ({
                                    label: template.label,
                                    value: template.id
                                }))}
                                onChange={(e) => setSelectedTemplateId(e.target.value)}
                            />
                        </div>
                        <div className="col-sm-3 col-12">
                            <button type="button" className="button-79 text-center theme-btn h-50" onClick={handleFetchDetails}>
                                Fetch Detail
                            </button>
                        </div>
                    </div>

                    {showFields && (
                        <Box mt={3}>
                            <Grid container spacing={3}>
                                {/* Form Fields */}
                                <Grid item xs={12} sm={6}>
                                    <div className="row">
                                        {/* Template Name */}
                                        {/* <div className="col-12 mb-3">
                        <TextField
                            fullWidth
                            label="Template Name"
                            autoComplete="off"
                            {...formik.getFieldProps('value')}
                            size="small"
                            sx={inputStyles(theme)}
                        />
                    </div> */}
                                        {/* Template Value */}
                                        {/* <div className="col-12 mb-3">
                        <TextField
                            fullWidth
                            label="Template Value"
                            {...formik.getFieldProps('template_value')}
                            size="small"
                            sx={inputStyles(theme)}
                        />
                    </div> */}
                                        {/* Template */}
                                        {/* <div className="col-12 mb-3">
                                            <TextField
                                                fullWidth
                                                label="Template"
                                                multiline
                                                autoComplete="off"
                                                disabled
                                                {...formik.getFieldProps('template')}
                                                minRows={4}
                                                size="small"
                                                sx={inputStyles(theme)}
                                            />
                                        </div> */}
                                        {/* Header Variable */}
                                        {
                                            formik.values.isHeaderVariableRequired == true && (
                                                <div className="col-12 mb-3">
                                                <TextField
                                                    fullWidth
                                                    label="Header Variable"
                                                    value={formik.values.header_variable || ''}
                                                    onChange={(e) => formik.setFieldValue('header_variable', e.target.value)}
                                                    size="small"
                                                    sx={inputStyles(theme)}
                                                />
                                                {headerValueError && <div style={{ color: 'red' }}>{headerValueError}</div>}
                                            </div>
                                            )
                                        }
                                      
                                        {/* Button URL */}
                                        {
                                            formik.values.isButtonUrlRequired == true && (
                                                <div className="col-12 mb-3">
                                                <TextField
                                                    fullWidth
                                                    label="Button Url"
                                                    {...formik.getFieldProps('button_url')}
                                                    size="small"
                                                    sx={inputStyles(theme)}
                                                />
                                            {buttonUrlError && <div style={{ color: 'red' }}>{buttonUrlError}</div>}
                                            </div>
                                            )
                                        }
                                       
                                        {/* Variables */}
                                        <div className="col-12 mb-3">
                                            {Array.isArray(formik.values.variables) && formik.values.variables.length > 0
                                                ? formik.values.variables.map((value, index) => (
                                                      <div
                                                          key={index}
                                                          style={{
                                                              display: 'flex',
                                                              alignItems: 'center',
                                                              marginBottom: '12px',
                                                              width: '100%'
                                                          }}
                                                      >
                                                          <Typography
                                                              variant="body1"
                                                              sx={{ minWidth: '15%', textAlign: 'left' }}
                                                              gutterBottom
                                                          >
                                                              {`{{${index + 1}}}`}
                                                          </Typography>
                                                          
                                                          <TextField
                                                              fullWidth
                                                              label={`Variable ${index + 1}`}
                                                              value={formik.values.variables[index] || ''}
                                                              onChange={(e) => {
                                                                  const newVariables = [...formik.values.variables];
                                                                  newVariables[index] = e.target.value;
                                                                  formik.setFieldValue('variables', newVariables);
                                                              }}
                                                              size="small"
                                                          />
                                                      </div>
                                                  ))
                                                  : null}
                                                  {variableError && <div style={{ color: 'red' , marginLeft:'15%' }}>{variableError}</div>}
                                        </div>
                                    </div>
                                </Grid>

                                {/* Template Preview */}
                                <Grid item xs={12} sm={6}>
                                    <Box p={3} border={1} borderRadius="8px" borderColor={theme.palette.divider}>
                                        <Typography variant="h6" gutterBottom>
                                            <strong>Template Preview</strong>
                                        </Typography>

                                        {/* {/ Dynamically replace placeholders in the template /} */}
                                        <Typography variant="body1" component="pre" style={{ whiteSpace: 'pre-wrap' }}>
                                            {formik.values.template.replace(/\{\{(.*?)\}\}/g, (_, placeholder) => {
                                                // Replace "header" placeholder
                                                if (placeholder === 'header_1') {
                                                    return formik.values.header_variable || `{{${placeholder}}}`;
                                                }

                                                // Replace numbered placeholders
                                                const variableIndex = parseInt(placeholder, 10) - 1;
                                                if (!isNaN(variableIndex) && variableIndex >= 0) {
                                                    return formik.values.variables?.[variableIndex] || `{{${placeholder}}}`;
                                                }

                                                // Default return for unmatched placeholders
                                                return `{{${placeholder}}}`;
                                            })}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                         {/* Checkbox to show/hide test email fields */}
            <Grid container>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showTestMessageFields}
                                onChange={(e) => setShowTestMessageFields(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Test Whatsapp Message"
                    />
                </Grid>
            </Grid>
              {/* Conditionally render TextField based on checkbox */}
              {showTestMessageFields && (
                <>
                 <div className="col-12 mb-3">
                 <Autocomplete
                   multiple
                   freeSolo
                   autoComplete="off"
                   fullWidth
                   id="Variable-input"
                   value={chipArray}
                   options={[]}
                   onInputChange={handleInputChange}
                   onKeyDown={handleInputKeyDown}
                   renderInput={(params) => (
                     <TextField
                       {...params}
                       label="Number"
                       value={inputValue}
                       autoComplete="off"
                       size="small"
                       type="number"
                       onChange={(e) => handleInputChange(e, e.target.value)}
                     />
                   )}
                   renderTags={(value, getTagProps) => 
                     value.map((option, index) => (
                       <Chip
                         key={index}
                         label={option}
                         {...getTagProps({ index })}
                         onDelete={() => handleDelete(option)} // Delete the chip
                       />
                     ))
                   }
                 />
                 {numberError && <div style={{ color: 'red' }}>{numberError}</div>}
               </div>
              
           </>
            )}
             <div className="col-12 mt-4">
               <button
                   className="button-79"
                   type="button"
                   onClick={() => {
                    if (validateName()) {
                        sendTestMessage({
                            deal_id: dealId.id,
                            template_name: formik.values.value,
                            template_text: formik.values.template,
                            template_value: formik.values.template_value,
                            header_value: formik.values.header_variable[0],
                            variables: formik.values.variables,
                            button_url: formik.values.button_url,
                            message_type: showTestMessageFields ? "test" : "manual",
                            test_mobile_numbers: chipArray
                        });
                    }
                }}
                
               >
                   Submit
               </button>
           </div>
                        </Box>
                    )}
                </Form>
            </FormikProvider>
        </Box>
    );
};

export default MessageTemplatePreview;
